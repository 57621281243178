import React from 'react';
import './lib/variables.scss'
import './lib/global.scss'
import './lib/layout.scss'
import './lib/buttons.scss'
import './lib/classes.scss'

import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import PubSub from 'pubsub-js';
import events from './lib/events';
import Alert from './UIComponents/Alert/Alert'
import NoMatch from "./pages/404";
import Login from "./pages/Login";
import Scrape from "./pages/Scrape";
import Incident from "./pages/Incident";
import BrowseIncidents from "./pages/BrowseIncidents";
import NullDataFlags from "./pages/NullDataFlags";
import InclusionKeywords from "./pages/InclusionKeywords";
import ExclusionKeywords from "./pages/ExclusionKeywords";
import Sources from "./pages/Sources";
import SourceDetails from "./pages/SourceDetails";
import ColumnDefinitions from "./pages/ColumnDefinitions";
import {NotificationProvider} from "./UIComponents/IncidentNotifications/NotificationsContext";
import AlignDir from "./services/languages/AlignDir";
import withLanguage from "./services/languages/withLanguage";
import {langProperties, Language} from "./services/languages/LanguageContext";
import Preferences from "./pages/Preferences";
import IncidentsTableNotifications from "./UIComponents/IncidentNotifications/NotificationsDisplay";
import { KeyStatesProvider } from './services/keyStates';

interface IAppProps {
  language: Language; // Define prop types as needed
  setLanguage: (language: Language) => void;
}

interface IAppState {
  alertQueue: any[]
}

class App extends React.Component <IAppProps, IAppState> {
  constructor(props:IAppProps) {
    super(props);
    this.state = {
      alertQueue: []
    };
    PubSub.subscribe(events.alert, (msg: string, data: any) => {
      this.alert(data)
    });
    PubSub.subscribe(events.clearAlert, (msg: string, data: any) => {
      this.clearAlert(data)
    });
  }

  componentWillUnmount() {
    PubSub.clearAllSubscriptions();
  }

  alert = (alert:any) => {
    let alertQueue = this.state.alertQueue.slice();
    if(alert.flush){
      alertQueue = [alert];
    }
    else{
      alertQueue.push(alert);
    }
    this.setState({alertQueue});
  };

  clearAlert = (options:any) => {
    let alertQueue = this.state.alertQueue.slice();
    if(options.clearAll){
      alertQueue = [];
    }
    else{
      alertQueue.splice(0, 1);
    }
    this.setState({alertQueue});
  };


  render() {
    return (
        <AlignDir direction={langProperties[this.props.language].dir}>
          <NotificationProvider>
            <KeyStatesProvider>
        <Router>
          <div dir={langProperties[this.props.language].dir}>
            <meta/>
            <Routes >
              <Route path="/" element={<Login/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/scrape" element={<Scrape/>}/>
              <Route path="/incident/:id" element={<Incident/>}/>
              <Route path="/incidents" element={<BrowseIncidents/>}/>
              <Route path="/null-flags" element={<NullDataFlags/>}/>
              <Route path="/keywords" element={<InclusionKeywords/>}/>
              <Route path="/exclusion-keywords" element={<ExclusionKeywords/>}/>
              <Route path="/sources" element={<Sources/>}/>
              <Route path="/source-details" element={<SourceDetails/>}/>
              <Route path="/columns" element={<ColumnDefinitions/>}/>
              <Route path="/preferences" element={<Preferences/>}/>
              <Route path="/*" element={<NoMatch/>}/>
            </Routes >
            <IncidentsTableNotifications />
            <Alert setQueue={(alertQueue:any)=>this.setState({alertQueue})} queue={this.state.alertQueue}/>
          </div>
        </Router>
            </KeyStatesProvider>
          </NotificationProvider>
        </AlignDir>
    );
  }
}

export default withLanguage(App)