import React from 'react'
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {IColumnSelect} from "../../../services/types/columns";
import BaseIncidentField from "./BaseIncidentField";
import {cn} from "../../../services/utils";
import {getTextDirection} from "../../../services/multiLangUI";
import stringSimilarity from 'string-similarity';

export default class IncidentFieldSelect extends BaseIncidentField<IColumnSelect> {
    render() {
        const column = this.props.column;
        const data = this.props.data;
        const emptyChecker = this.props.emptyChecker;
        const sources = this.props.sources;
        const onSourcesClick = this.props.onSourcesClick;
        const onEdit = this.props.onEdit;
        const onClick = this.props.onClick;
        const optionalValues = column.options.slice();
        const value = emptyChecker(data) ? "unknown" : stringSimilarity.findBestMatch(("" + (data || "")), optionalValues.map(o=>emptyChecker(o) ? "unknown" : (o||"").toLowerCase())).bestMatch.target.toLowerCase();
        const labelOverride = this.props.labelOverride

        return <div className={cn({
            "empty-data": emptyChecker(data),
            "card-leaf-data-wrap": true
        })}>
            <FormControl className={"fill-width-util"}>
                <div className={"sources-adornment"}>
                    {this.getLabelSourcesAdornment(column, sources, onSourcesClick)}
                </div>
                <InputLabel
                    id={column.key}
                >
                    {labelOverride !== undefined ? labelOverride : column.title}
                </InputLabel>
                <Select
                    labelId={column.key}
                    label={labelOverride !== undefined ? labelOverride : column.title}
                    variant="outlined"
                    value={value}
                    className={"fill-width-util"}
                    readOnly={!onEdit}
                    dir={getTextDirection(value||"")}
                    onClick={()=>{
                        if(onClick){
                            onClick(data);
                        }
                    }}
                    onChange={(e)=>{
                        if(onEdit){
                            debugger
                            onEdit(e.target.value, null);
                        }
                    }}
                >
                    {
                        optionalValues.map((o: string, i: number)=>{
                            const opValue = emptyChecker(o) ? "unknown" : (o||"").toLowerCase();
                            return <MenuItem value={opValue} key={"option_" + i}>{o}</MenuItem>
                        })}
                </Select>
            </FormControl>
        </div>
    }
}